import React from 'react'
import Layout from '@/components/layout'
import { SEO } from '@/components/seo'
import { PageProps } from 'gatsby'

const title = '404 Not Found'

const NotFound = (props: PageProps) => (
  <Layout title={title}>
    <SEO title={title} pathname={props.location.pathname} />
    <h3 className="text-lg md:text-xl text-center font-bold">お探しのページは見つかりませんでした。</h3>
  </Layout>
)

export default NotFound
